import ReactDOM from 'react-dom/client'
import './index.css'

import { createHashRouter, RouterProvider } from 'react-router-dom'
import React from 'react'

import { Home } from './Home'
import { About } from './About'

const router = createHashRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: 'about',
    element: <About />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<RouterProvider router={router} />)
