import EmailIcon from './assets/images/Email.svg'
import LinkedinIcon from './assets/images/Linkedin.svg'

export const Footer = () => {
  return (
    <footer className="Footer">
      <div className="Footer__Socials">
        <a href="mailto:someone@yoursite.com">
          <img src={EmailIcon} alt="Email me" className="Footer__Email" />
        </a>
        <a
          href="https://www.linkedin.com/in/alexandra-elena-guta-9a84b822a/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={LinkedinIcon} alt="LinkedIn" className="Footer__LinkedIn" />
        </a>
      </div>
      <p className="Footer__Text">Made with ♥ by Alexandra</p>
    </footer>
  )
}
