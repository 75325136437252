import CryptoFix_Icon from "./assets/images/CryptoFix.jpg";
import MessageMail_Icon from "./assets/images/MessageMail.jpg";
import Scaffolding_a_new_company_Icon from "./assets/images/Scaffolding_a_new_company.jpg";
import favfruits_Icon from "./assets/images/favfruits.jpg";
import Emergencee_Icon from "./assets/images/Emergencee.jpg";
import Gallery_of_Archery_Icon from "./assets/images/Gallery_of_Archery.jpg";
import WhateverSoft_Icon from "./assets/images/WhateverSoft.jpg";
import ShoeBrand_Icon from "./assets/images/ShoeBrand.jpg";
import Couchly_Icon from "./assets/images/Couchly.jpeg";

import cert1Image from "./assets/images/cert1.png";
import cert2Image from "./assets/images/cert2.png";
import cert3Image from "./assets/images/cert3.png";
import cert4Image from "./assets/images/cert4.png";
import cert5Image from "./assets/images/cert5.png";
import cert7Image from "./assets/images/cert7.png";

import designCert7Image from "./assets/images/designCert1.jpeg";

export const projects = [
  {
    key: "couchly",
    name: "Couchly",
    url: "https://www.figma.com/file/3Uhez8swYi1HqigtmX1cKS/Couchly.?type=design&node-id=413%3A705&t=e3pPqE20B55QOWGS-1",
    source: Couchly_Icon,
    description:
      "Couchly represents a simple way by which users can get inspired in terms of furnishing their dream home. After viewing several sets of furniture for different rooms and styles of a home, as well as certain popular items, customers can leave a message to further collaborate with the Couchly team to establish the details together.",
    type: "UI Product Design, Desktop, Tablet, Mobile",
  },
  {
    key: "cryptofix",
    name: "CryptoFix",
    url: "https://www.figma.com/file/dfsGPfKPDEBsIUsiuG1E8J/CryptoFix-Project?type=design&node-id=905%3A646&t=8NwzWwbjuqP8DDTZ-1",
    source: CryptoFix_Icon,
    description:
      "Advertisers can join CryptoFix and create ad campaigns.\nCryptocurrency exchanges, blogs, and influencers",
    type: "UI Product Design, Desktop, Tablet, Mobile",
  },
  {
    key: "messagemail",
    name: "MessageMail",
    url: "https://www.figma.com/file/tlErTnblPt9MSiIWkVfzWf/messagemail?type=design&node-id=604%3A166&t=ryYVA2l2vEPF0gW3-1",
    source: MessageMail_Icon,
    description:
      "A central location in which all of your DM's are presented to you in a fashion similar to email.",
    type: "UI Product Design, Desktop, Tablet, Mobile",
  },
  {
    key: "scaffolding",
    name: "Scaffolding a new company",
    url: "https://www.figma.com/file/6XvjJQuWU1CoGtHwmKBlLn/responsive-lp-before-Ale?node-id=0%3A1&t=GuxNicQ6XEevwU5A-1",
    source: Scaffolding_a_new_company_Icon,
    description: "A simple landing page with a representation of cards.",
    type: "UI Product Design, Desktop, Tablet, Mobile",
  },
  {
    key: "favfruits",
    name: "favfruits",
    url: "https://www.figma.com/file/vjcLe5qjOZImZ1SX1kpgeb/Untitled?node-id=0%3A1&t=aDWwk1CtAZOn17Z0-1",
    source: favfruits_Icon,
    description:
      "An app where the user can search info about the favorite fruits.",
    type: "UI Product Design, Tablet",
  },
  {
    key: "emergencee",
    name: "Emergencee",
    url: "https://www.figma.com/file/rjxrfhDCAJRTTWM2aFLF3T/emergencee?node-id=0%3A1&t=bEIacvKnMWnMLfIw-1",
    source: Emergencee_Icon,
    description: "A simple hero section in a landing page.",
    type: "UI Product Design, Desktop",
  },
  {
    key: "gallery_of_archery",
    name: "Gallery of Archery",
    url: "https://www.figma.com/file/vFqDfYfiXrrfUlZ143biXa/archery-challenge?node-id=0%3A1&t=6NJyIx62A3nFfePL-1",
    source: Gallery_of_Archery_Icon,
    description: "Here I present different ways to arrange a gallery.",
    type: "UI Product Design, Desktop",
  },
  {
    key: "whateversoft",
    name: "WhateverSoft",
    url: "https://www.figma.com/file/Xyk02OjrErR17uQLShyJgv/whateversoft?node-id=0%3A1&t=lxn1CO3EP7Tuvi6T-1",
    source: WhateverSoft_Icon,
    description: "Another hero section presented in different ways.",
    type: "UI Product Design, Desktop",
  },
  {
    key: "shoebrand",
    name: "ShoeBrand",
    url: "https://www.figma.com/file/JY7U9B1xGBWBwfLr0uocUF/features-testimonials-challenge?node-id=0%3A1&t=1NsMbP4teWRyU0ma-1",
    source: ShoeBrand_Icon,
    description: "This is a representing of a homepage of a shoes store.",
    type: "UI Product Design, Desktop",
  },
];

export const credits = [
  {
    title: "Google UX Design - Coursera.com",
    certificates: [
      {
        key: "cert2",
        name: "Foundations of User Experience (UX) Design",
        url: "https://www.coursera.org/account/accomplishments/verify/5Q4J7STJ69NR",
        source: cert2Image,
        border: false,
        descriptions: [
          "Identify and compare disciplines within UX",
          "Identify the factors that contribute to great user experience design",
          "Define the field of UX and explain its importance for consumers and businesses",
          "Review common job responsibilities of entry-level UX designers",
        ],
      },
      {
        key: "cert7",
        name: "Start the UX Design Process: Empathize, Define, and Ideate",
        url: "https://www.coursera.org/account/accomplishments/verify/TJTP33VPG897",
        source: cert7Image,
        border: false,
        descriptions: [
          "Describe the role of UX research in the design process",
          "List common research methods in UX design",
          "Explain research methods to use during the product development life cycle",
          "Identify and account for biases in UX research",
        ],
      },
      {
        key: "cert3",
        name: "Build Wireframes and Low-Fidelity Prototypes",
        url: "https://www.coursera.org/account/accomplishments/verify/4KAEQ5KEDUTY",
        source: cert3Image,
        border: false,
        descriptions: [
          "Build a goal statement",
          "Outline a user flow",
          "Explain the difference between low-fidelity and high-fidelity design",
          "Describe the purpose of wireframing in the design process",
        ],
      },
      {
        key: "cert4",
        name: "Conduct UX Research and Test Early Concepts",
        url: "https://www.coursera.org/account/accomplishments/verify/J24EYWXYLEGP",
        source: cert4Image,
        border: false,
        descriptions: [
          "Build a script for a UX research study",
          "Determine participants for a UX research study",
          "Define Key Performance Indicators for a UX research study",
          "Plan a UX research study",
        ],
      },
      {
        key: "cert5",
        name: "Create High-Fidelity Designs and Prototypes in Figma",
        url: "https://www.coursera.org/account/accomplishments/verify/B87QG78VDR5L",
        source: cert5Image,
        border: false,
        descriptions: [
          "Create a mockup in Figma",
          "Apply foundational elements of visual design to mockups, including typography, color, and iconography",
          "Use grids and containment elements in mockup layouts",
          "Demonstrate the benefits of using negative space to improve mockups",
        ],
      },
      {
        key: "cert1",
        name: "Responsive Web Design in Adobe XD",
        url: "https://www.coursera.org/account/accomplishments/verify/FA3XPCMRRMGC",
        source: cert1Image,
        border: false,
        descriptions: [
          "Explain the importance of understanding user needs, behaviors, and motivations",
          "Develop personas",
          "Conduct user research to create user journey maps and identify pain points",
          "Write a problem statement",
        ],
      },
    ],
  },
  {
    title: "Garry Simon DesignCours - DesignCours.com",
    certificates: [
      {
        key: "designCert1",
        name: "Start the UX Design Process: Empathize, Define, and Ideate",
        url: "https://designcourse.com/certificate/63682719a0cc35001ba97fac",
        source: designCert7Image,
        border: true,
        descriptions: [
          "Start the UX Design Process: Empathize, Define, and Ideate Certification",
        ],
      },
    ],
  },
];

export const MOBILE_MIN_WIDTH = 600;

export const RESUME_LINK =
  "https://drive.google.com/file/d/1xoRGequo_wR9gU0KFNY1Z5CSoEAJ59Wl/view?usp=share_link";
