import { projects } from './const'
import { parseText } from './utils'

import { Menu } from './Menu'
import { Footer } from './Footer'

import HeroImage from './assets/images/Hero.svg'
import ScrollImage from './assets/images/Scroll.svg'

import BackgroundImage from './assets/images/Background.svg'

export const Home = () => {
  return (
    <>
      <main className="Home">
        <img className="Background__Image" src={BackgroundImage} alt="Hero" />
        <Menu />
        <div className="Hero">
          <div className="Hero__Section">
            <h1 className="Hero__Title">Hello</h1>
            <h1 className="Hero__Title">I'm Alexandra.</h1>
            <h2 className="Hero__Subtitle">UI/UX designer</h2>
          </div>
          <img src={HeroImage} alt="Hero" className="Hero__Image" />
        </div>
        <img src={ScrollImage} alt="Scroll" className="Scroll__Image" />
      </main>
      <section className="Projects">
        {projects.map((project) => {
          return (
            <div className="Project__Card" key={project.key}>
              <img
                className="Project__Image"
                src={project.source}
                alt={project.name}
              />
              <div className="Project__Info">
                <h1 className="Project__Name">{project.name}</h1>
                <p className="Project__Description">
                  {parseText(project.description)}
                </p>
                <a
                  className="Project__Link"
                  target="_blank"
                  rel="noreferrer"
                  href={project.url}
                >
                  <p className="Project__Link__Text">Check prototype here.</p>
                </a>
                <p className="Project__Type">{project.type}</p>
              </div>
            </div>
          )
        })}
      </section>
      <Footer />
    </>
  )
}
