import { useState, useEffect } from 'react'

export const parseText = (text: string) => {
  const lines = text.split('\n')
  if (lines.length === 1) return text

  return lines.map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    )
  })
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
