import { credits } from './const'
import { parseText } from './utils'

import { Menu } from './Menu'
import { Footer } from './Footer'

import HeroImage from './assets/images/Hero.svg'
import ScrollImage from './assets/images/Scroll.svg'

import BackgroundImage from './assets/images/Background.svg'

export const About = () => {
  return (
    <>
      <main className="About">
        <img className="Background__Image" src={BackgroundImage} alt="Hero" />
        <Menu />
        <div className="Hero">
          <div className="Hero__Section">
            <h1 className="Hero__Title">Hello</h1>
            <h1 className="Hero__Title">I'm Alexandra.</h1>
            <h2 className="Hero__Subtitle">UI/UX designer</h2>
          </div>
          <img src={HeroImage} alt="Hero" className="Hero__Image" />
        </div>
        <img src={ScrollImage} alt="Scroll" className="Scroll__Image" />
      </main>
      <section className="Projects">
        {credits.map((credit) => {
          return (
            <>
              <p className="Project__Title">{credit.title}</p>
              {credit.certificates.map((certificate) => {
                return (
                  <div className="Project__Card" key={certificate.key}>
                    <img
                      className={`Project__Image${
                        !certificate.border ? ' Project__Image__NoBorder' : ''
                      }`}
                      src={certificate.source}
                      alt={certificate.name}
                    />
                    <div className="Project__Info">
                      <h1 className="Project__Name">{certificate.name}</h1>
                      <p className="Project__Description">
                        {certificate.descriptions.map((description) => {
                          return <p>&#x2022; {parseText(description)}</p>
                        })}
                      </p>
                      <a
                        className="Project__Link"
                        target="_blank"
                        rel="noreferrer"
                        href={certificate.url}
                      >
                        <p className="Project__Link__Text">View certificate</p>
                      </a>
                    </div>
                  </div>
                )
              })}
            </>
          )
        })}
      </section>
      <Footer />
    </>
  )
}
