import { MOBILE_MIN_WIDTH, RESUME_LINK } from "./const";
import { useWindowDimensions } from "./utils";
import MenuIcon from "./assets/images/Menu.svg";
import CloseMenuIcon from "./assets/images/CloseMenu.svg";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export const Menu = () => {
  const { width } = useWindowDimensions();
  const isMobile = width < MOBILE_MIN_WIDTH;

  const [openDrawer, toggleDrawer] = useState(false);
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isMobile) return;
    const closeDrawer = (event: MouseEvent) => {
      if (
        drawerRef.current &&
        drawerRef.current.contains(event.target as Node)
      ) {
        return;
      }

      handleCloseDrawer();
    };

    document.addEventListener("mousedown", closeDrawer);
    return () => document.removeEventListener("mousedown", closeDrawer);
  }, [isMobile]);

  const handleOpenDrawer = () => {
    document.body.classList.add("no-scroll");
    toggleDrawer(true);
  };

  const handleCloseDrawer = () => {
    document.body.classList.remove("no-scroll");
    toggleDrawer(false);
  };

  const location = useLocation();

  return (
    <header className="Header">
      {isMobile ? (
        <>
          <img src={MenuIcon} alt="Menu icon" onClick={handleOpenDrawer} />
          <div
            className={`Header__Drawer${openDrawer ? " open" : ""}`}
            ref={drawerRef}
          >
            <img
              className="Header__CloseMenu"
              src={CloseMenuIcon}
              alt="Close menu"
              onClick={handleCloseDrawer}
            />
            <div className="Header__Buttons">
              <Link className="Header__Button" to={"/"}>
                Home
              </Link>
              <Link className="Header__Button" to={"/about"}>
                About me
              </Link>
              <a
                className="Header__Link"
                target="_blank"
                rel="noreferrer"
                href={RESUME_LINK}
              >
                <p className="Header__Button">Resume</p>
              </a>
            </div>
          </div>
        </>
      ) : (
        <div className="Header__Buttons">
          <Link
            className={`Header__Button${
              location.pathname === "/" ? " Header__Button__Selected" : ""
            }`}
            to={"/"}
          >
            Home
          </Link>
          <Link
            className={`Header__Button${
              location.pathname === "/about" ? " Header__Button__Selected" : ""
            }`}
            to={"/about"}
          >
            About me
          </Link>
          <a
            className="Header__Link"
            target="_blank"
            rel="noreferrer"
            href={RESUME_LINK}
          >
            <p className="Header__Button">Resume</p>
          </a>
        </div>
      )}
    </header>
  );
};
